import { Game } from "@lobby/core/src/entities";
import {
  APIError,
  CLIENT_ERRORS,
  ClientError,
  Icon,
  emitter,
  useMobile,
} from "@lobby/core/src/shared";
import { onlineManager } from "@tanstack/react-query";
import { Link, useNavigate, useParams } from "@tanstack/react-router";
import { PlayerInfo } from "features/player/player-info";
import { TakeBonusButton } from "features/take-bonus-button";
import { useEffect, useRef, useState } from "react";
import { FloatGroup } from "shared/ui";
import { Logo } from "shared/ui/logo";
import { Header } from "widget/header";
import { JackpotTickers } from "widget/jackpot-ticker-slots";
import { MiniGame, MiniGameContextProvider } from "widget/mini-game";
import { isGameExistInHeader as checkGameExistInHeader } from "../lib/helpers";
import { GamePageControlsMobile } from "./game-page-controls.mobile";

import "./styles.css";

export function GamePage() {
  // @ts-ignore
  const { gameId: gameStringId } = useParams({ from: "/_auth/game/$gameId" });
  const navigate = useNavigate();
  const [iframeKey, setIframeKey] = useState<string>(Date.now().toString());

  const isMobile = useMobile();

  const { data: categoriesList } = Game.useCategoriesList();

  const theme = (localStorage.getItem("theme") as "light" | "dark") ?? "dark";

  const { data } = Game.useGameRun({
    gameStringId,
    place: "game_list",
    lang: localStorage.getItem("locale") ?? "en",
    deviceType: isMobile ? "mobile" : "desktop",
    theme,
    color: theme === "dark" ? "13c7bc" : "43ada8",
  });

  const gameUrl = data?.result?.url;

  const isGameExistInHeader =
    categoriesList && checkGameExistInHeader(categoriesList, gameStringId);

  const reloadIframe = () => setIframeKey(Date.now().toString());

  useEffect(() => {
    if (data?.error) {
      const error = data.error;
      navigate({ to: "/casino", replace: true, search: true });

      const apiError = new APIError(error.message, {
        code: error.code,
        game: gameStringId,
      });

      emitter.emit("ERROR", { error: apiError });
    }
  }, [data, navigate, gameStringId]);

  useEffect(() => {
    const unbinds = [
      emitter.on("THEME_CHANGED", reloadIframe),
      emitter.on("SET_LOCALE", reloadIframe),
    ];

    if (!onlineManager.isOnline()) {
      emitter.emit("ERROR", { error: new ClientError(CLIENT_ERRORS[0]) });
    }

    return () => {
      unbinds.forEach((unbind) => unbind());

      onlineManager.subscribe((isOnline) => {
        if (!isOnline) {
          emitter.emit("ERROR", { error: new ClientError(CLIENT_ERRORS[0]) });
        }
      });
    };
  }, []);

  return (
    <>
      <MiniGameContextProvider>
        <MiniGame />
      </MiniGameContextProvider>
      <div className="game-frame fixed inset-0 z-[100] h-dvh w-screen overflow-y-hidden">
        <div className="h-full">
          <div className="flex h-full flex-col">
            {isGameExistInHeader ? <Header /> : <GamePageHeader gameStringId={gameStringId} />}
            <div className="grow">{gameUrl && <IFrame url={gameUrl} meta={iframeKey} />}</div>
          </div>
        </div>
      </div>

      <FloatGroup>
        <TakeBonusButton />
      </FloatGroup>
    </>
  );
}

function IFrame({ url, meta }: { url: string; meta: string }) {
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const navigate = useNavigate();

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (!iframeRef.current) return;

    const controller = new AbortController();

    /*
      Fortune Panda provider's games redirect to the lobby itself
      on click of the "Back" button it the game. It results having
      casino page opened in the iframe. So it was decided to
      redirect the user to the casino page.
    */
    iframeRef.current.addEventListener(
      "load",
      (e) => {
        const iframe = e.target as HTMLIFrameElement;
        const searchParams = new URLSearchParams(iframe?.contentWindow?.location.search);

        if (searchParams.get("forceGameExit")) {
          navigate({ to: "/casino" });
        }
      },
      { signal: controller.signal },
    );

    return () => controller.abort();
  }, []);

  return (
    <iframe
      ref={iframeRef}
      key={meta}
      className="size-full"
      id="game-frame"
      title="game-frame"
      src={url}
    />
  );
}

function GamePageHeader({ gameStringId }: { gameStringId: string }) {
  const isMobile = useMobile();
  const navigate = useNavigate();

  const game = Game.getGameByStringId(gameStringId);
  const redeemProviderBalance = Game.useRedeemProviderBalance();

  function onGameClose() {
    if (game?.isWalletIntegration) {
      redeemProviderBalance.mutate({ gameStringId });
    }
  }

  if (isMobile) {
    return (
      <div className="relative mobile-only:p-0.5 p-2.5">
        <JackpotTickers compact />
        <GamePageControlsMobile gameStringId={gameStringId} onGameClose={onGameClose} />
      </div>
    );
  }

  return (
    <div className="flex items-center justify-between px-5 py-3">
      <Link to="/" className="shrink-0">
        <Logo className="h-14" />
      </Link>
      <JackpotTickers className="game-frame__jackpot-tickers" />
      <div className="flex h-full items-center gap-5">
        <PlayerInfo />
        <button
          type="button"
          onClick={() => {
            onGameClose();
            navigate({ to: "/casino" });
          }}
        >
          <Icon
            className="text-keppel hover:text-java text-[2rem] dark:text-java dark:hover:text-keppel"
            name="closeAlt"
          />
        </button>
      </div>
    </div>
  );
}
