import { Icon, LanguageSelector, ThemeSwitch, useAuth, useModal } from "@lobby/core/src/shared";
import { useTranslate } from "@lobby/ocb-intl";
import { Link } from "@tanstack/react-router";
import { MobileMenuButton } from "features/mobile-menu-button";
import { PlayerInfo } from "features/player";
import { Logo } from "shared/ui/logo";
import { useHeaderCategories } from "../lib";
import { NavigationList } from "./components";

export function Header() {
  const { $t } = useTranslate();
  const { isAuth } = useAuth();
  const { open: openLogInModal } = useModal("log-in");
  const headerCategories = useHeaderCategories();

  return (
    <header className="sticky top-0 z-10 lg:h-17.5">
      <div className="mx-auto flex h-full mobile-only:flex-col justify-between lg:max-w-screen-3xl lg:px-5">
        <nav className="mobile-only:order-2 flex lg:h-full lg:items-center lg:overflow-x-auto overflow-y-hidden">
          <div className="mobile-only:hidden flex-shrink-0 me-5">
            <Link to="/">
              <Logo className="h-14" />
            </Link>
          </div>
          <NavigationList items={headerCategories} />
        </nav>

        <div className="mobile-only:hidden">
          <div className="flex items-center h-full gap-5">
            <LanguageSelector />
            <ThemeSwitch className="mobile-only:hidden" />

            <div className="flex h-full items-center gap-5 lg:contents">
              {isAuth ? (
                <PlayerInfo />
              ) : (
                <div
                  onClick={openLogInModal}
                  className="flex items-center gap-1 font-medium cursor-pointer"
                >
                  <Icon name="signIn" className="text-15" />
                  <span className="first-letter:capitalize">
                    {$t({ defaultMessage: "sign in" })}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="lg:hidden flex items-center justify-between order-1 gap-1 px-5 py-2">
          <div className="flex items-center gap-3 shrink-0">
            <MobileMenuButton />
            <Link to="/">
              <Logo className="h-11" />
            </Link>
          </div>

          {isAuth ? (
            <PlayerInfo />
          ) : (
            <div
              onClick={openLogInModal}
              className="flex items-center gap-1 font-medium cursor-pointer"
            >
              <Icon name="signIn" className="text-15" />
              <span className="first-letter:capitalize">{$t({ defaultMessage: "sign in" })}</span>
            </div>
          )}
        </div>
      </div>
    </header>
  );
}
