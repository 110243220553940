import { Lobby } from "@lobby/core/src/entities";
import { Button, LanguageSelector, ThemeSwitch, useModal } from "@lobby/core/src/shared";
import { useTranslate } from "@lobby/ocb-intl";
import usdtLogoImg from "assets/usdt_logo.png";
import { LoginForm } from "features/log-in-form";
import { Logo } from "shared/ui/logo";
import { Modal } from "shared/ui/modal";

export function LogInModal({ isStatic = false }: { isStatic?: boolean }) {
  const { isOpen, close } = useModal("log-in");

  return (
    <Modal open={isOpen}>
      <Modal.Overlay onClick={isStatic ? undefined : close} />
      <LogInModalComponent />
    </Modal>
  );
}

function LogInModalComponent() {
  const { open: openRegisterModal } = useModal("register");
  const { $t } = useTranslate();

  const { data: lobbyData } = Lobby.useLobby();

  const isRegistrationEnabled = lobbyData?.registration.enabled ?? false;

  return (
    <Modal.Panel className="mobile-only:min-h-full w-full border-keppel bg-mercury p-5 lg:top-17 lg:w-[28.75rem] lg:rounded-rounded lg:border-1 dark:border-shark dark:bg-ebony-clay">
      <Logo className="mx-auto h-20 lg:h-24" />
      <div className="mt-5 flex-c-sb gap-5">
        <div className="font-bold text-lg uppercase lg:text-xl">
          {$t({ defaultMessage: "Log In" })}
        </div>
        {isRegistrationEnabled && (
          <Button variant="secondary" onClick={openRegisterModal}>
            {$t({ defaultMessage: "Sign up" })}
          </Button>
        )}
      </div>

      <LoginForm className="mt-5 mobile-only:mb-10" />

      <div className="w-44 lg:w-52 mx-auto">
        <img src={usdtLogoImg} alt="usdt logo" />
      </div>

      <div className="mt-auto flex items-center justify-between">
        <LanguageSelector orientation="top" />
        <ThemeSwitch />
      </div>
    </Modal.Panel>
  );
}
